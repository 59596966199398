import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useLayoutEffect, useState } from 'react';
import Router from 'next/router';
import axiosWithMjrAccessToken from 'utils/axiosWithMjrAccessToken';
import { useContent } from 'utils/swrCalls';
import { logError } from 'utils/errorLogger';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import CreateOrderFormWarrantyFields from 'components/CreateOrder/CreateOrderFormWarrantyFields';
import DollarInput from 'components/Global/DollarInput';
import * as site from 'utils/getSite';

/**
 * cleanCompanyNames
 *
 * @param compName
 * Adds spaces in some comapanyEnums to make it look cleaner
 */
function cleanCompanyNames(compName) {
  return (compName === 'JudithRipka') ? 'Judith Ripka'
    : (compName === 'KirkRichDial') ? 'Kirk Rich Dial'
      : (compName === 'CullenJewellery') ? 'Cullen Jewellery'
        : compName;
}

const CreateOrderFormFields = (props) => {
  const { data: content } = useContent();
  const { step1 } = content?.content.form;
  const {
    handleChange,
    values: {
      issue, customerNotes, declaredValueUsd, companyNameEnum, hasWNM,
    },
    submitHandler,
    errors,
    isValid,
    submitting,
    submitForm,
    setFieldValue,
  } = props;

  // set state
  const [companyNames, setCompanyNames] = useState({});
  const [loadingCompanyNames, setLoadingCompanyNames] = useState(true);

  /**
   * getCompanyNames
   *
   * Get company names from db for select options
   */
  const getCompanyNames = async () => {
    setLoadingCompanyNames(true);
    // make call to company names
    await axiosWithMjrAccessToken
      .get(`${process.env.NEXT_PUBLIC_PROXY_MJR_API_URL}/api/Company/GetCompanyNames`)
      .then((response) => {
        // Change name 'None' to 'No'
        if (response.data[0].name === 'None') {
          response.data[0].name = 'No';
        }
        setCompanyNames(response.data);
        setLoadingCompanyNames(false);
      })
      .catch((error) => {
        logError(error, 'getCompanyNames - /Components/CreateOrder/CreateOrderFormFields.js');
        if (error?.response?.status === 401) {
          setTimeout(() => {
            getCompanyNames();
          }, 500);
        }
      });
  };

  // on component load/refresh call company names
  useLayoutEffect(() => {
    getCompanyNames();
  }, []);

  let companyOptions = null;
  if (companyNames !== {} && companyNames.length) {
    companyOptions = companyNames.map((option) => (
      <option value={option.value} key={option.value}>
        {cleanCompanyNames(option.name)}
      </option>
    ));
    if (!site.getSite.includes('macys')) {
      companyOptions = companyOptions.filter((x) => x.key !== '1');
    }
  }

  // If montblanc site change text for label of select repair item field
  const repairLabel = step1.labelText;
  let repairLabelWidth = 150;
  let repairNotesPlaceholder = 'Please describe how you would like the item repaired. We will use this information to help prepare your repair estimate.';
  if (site.getSite.includes('macys')) {
    repairLabelWidth = 275;
  }
  if (site.getSite.includes('montblanc')) {
    repairLabelWidth = 300;
    repairNotesPlaceholder = 'Please include a description of the issue and repair service needed for your items';
  }
  // Redirects the user to mont blac mjr site if they select Mont Blac as the jwelery partner.
  if (!site.getSite.includes('montblanc') && companyNameEnum === '6') {
    Router.push(`${process.env.NEXT_PUBLIC_MONT_BLANC_URL}`);
  }

  // When company is changed away from Sears, wipe out Sears values
  const setCompany = (company) => {
    if (company.target.value !== '4') {
      setFieldValue('purchasedPrice', '', true);// purchasedPrice, purchasedDate, planNumber,
      setFieldValue('purchasedDate', null, true);
      setFieldValue('planNumber', '', true);
      // also hidden MA GUID incase this switch happens after CP is validated
      setFieldValue('maintenanceAgreementId', null, true);
    }
    handleChange(company);
  };

  return (
    <div className='step-one-wrapper form-wrapper'>
      <div className='question-1'>
        <Typography variant='h2'>{'Tell us about your repair needs'}</Typography>
        {content?.content?.form?.body
          ? (
            <Typography variant='body1'>
              {
                content?.content?.form?.body
              }
            </Typography>
          )
          : (
            <Typography variant='body1'>
              {
                'If you are uncertain what needs to be repaired, that’s OK. Our jewelers will professionally evaluate the piece and offer their suggestions.'
              }
            </Typography>
          )}
      </div>
      {companyNameEnum !== '6' && companyNameEnum !== '1' && (
        <div className='question-3'>
          <p>{'Is this from a My Jewelry Repair partner?'}</p>
          <LoadingSpinner loading={loadingCompanyNames} message='Getting Options...'>
            <FormControl variant='outlined'>
              <InputLabel htmlFor='provider'>{'- Select Option -'}</InputLabel>
              <Select
                native
                fullWidth
                value={companyNameEnum}
                onChange={setCompany}
                labelWidth={120}
                error={Boolean(errors.companyNameEnum)}
                required
                inputProps={{
                  name: 'companyNameEnum',
                  id: 'partnerSelect',
                }}
              >
                <option aria-label='None' value='' disabled />
                {companyOptions}
              </Select>
              {errors?.companyNameEnum && <FormHelperText error>{errors?.companyNameEnum}</FormHelperText>}
            </FormControl>
          </LoadingSpinner>
        </div>
      )}
      <div>
        <FormControl variant='outlined'>
          <InputLabel htmlFor='provider'>{repairLabel}</InputLabel>
          <Select
            native
            fullWidth
            value={issue}
            onChange={handleChange}
            labelWidth={repairLabelWidth}
            required
            error={Boolean(errors.issue)}
            inputProps={{
              name: 'issue',
            }}
          >
            <option aria-label='None' value='' disabled />
            {step1.repairIssues.map((repairIssue) => (
              <option value={repairIssue.name} key={repairIssue.name}>
                {repairIssue.name}
              </option>
            ))}
          </Select>
          {errors?.issue && <FormHelperText error>{errors?.issue}</FormHelperText>}
        </FormControl>
      </div>
      <div className='question-2'>
        <TextField
          name='customerNotes'
          placeholder={repairNotesPlaceholder}
          label='Repair Notes'
          onChange={handleChange}
          error={Boolean(errors.customerNotes)}
          helperText={errors.customerNotes ? errors.customerNotes : ''}
          multiline
          value={customerNotes}
          required
          rows='4'
          rowsMax='10'
          variant='outlined'
          margin='normal'
          fullWidth
        />
      </div>
      {companyNameEnum !== '4' && !(companyNameEnum === '1' && hasWNM) && (
        <div className='question-4'>
          <FormControl fullWidth variant='outlined'>
            <TextField
              name='declaredValueUsd'
              label='Declared Value'
              variant='outlined'
              value={declaredValueUsd}
              error={Boolean(errors.declaredValueUsd)}
              helperText={errors.declaredValueUsd ? errors.declaredValueUsd : '* This value will be used to calculate shipping insurance'}
              onChange={handleChange}
              InputProps={{
                inputComponent: DollarInput,
              }}
            />
          </FormControl>
        </div>
      )}
      {companyNameEnum === '6' && (
        <div className='warranty-info'>
          {content?.content?.form?.warrantyInfo
            ? (
              <Typography variant='body1'>
                {
                  content?.content?.form?.warrantyInfo
                }
              </Typography>
            )
            : (
              <Typography variant='body1'>
                {
                  'If your Montblanc item is under warranty, please enclose a legible photocopy of your warranty booklet or card. Please keep the original for your own reference and safekeeping.'
                }
              </Typography>
            )}
        </div>
      )}
      {(companyNameEnum !== '' || companyNameEnum !== 0) && <CreateOrderFormWarrantyFields {...props} />}
      <div className='form-controls'>
        <Button
          variant='contained'
          color='primary'
          id='addmore-order-button'
          onClick={() => submitHandler(submitForm, 'addMore')}
          disabled={!isValid || issue === '' || submitting === true}
        >
          {'Add Another Item'}
        </Button>
        <Button
          variant='contained'
          color='secondary'
          id='create-order-button'
          onClick={() => submitHandler(submitForm, 'next')}
          disabled={!isValid || issue === '' || submitting === true}
        >
          {submitting === false ? 'Next Step (Shipping)' : <CircularProgress size={20} />}
        </Button>
      </div>
    </div>
  );
};

export default CreateOrderFormFields;
