import * as Yup from 'yup';
import * as formValdation from 'utils/formValidation';
import * as site from 'utils/getSite';
import React, { useState } from 'react';
import Router, { useRouter } from 'next/router';

import { FormatDate } from 'utils/dateFormat';
import { Formik } from 'formik';
import axiosWithMjrAccessToken from 'utils/axiosWithMjrAccessToken';
import { logError } from 'utils/errorLogger';
import CreateOrderForm from 'components/CreateOrder/CreateOrderForm';
import { useCartGetter, useCartDispatcher } from 'contexts/CartContext';

/**
 * InputForm
 *
 * Top level component for order creation form
 * Controls submitting form and calls for validating
 * the warranty number
 *
 */
const CreateOrder = () => {
  // get route params to determine if its an edit item or not
  const routeParams = useRouter().query;
  const [state, setState] = useState({
    hasMessage: false,
    message: '',
    submit: false,
  });
  const [formType, setFormType] = useState('next');
  const cart = useCartGetter();
  const dispatchCart = useCartDispatcher();

  /**
   * Continue Form
   *
   * @param id - index from cart
   * @param cartItem - item to add to cart
   *
   * Routing and form clear logic for continuing form
   * @param resetForm
   */
  const continueForm = (id, cartItem, resetForm) => {
    setState({ submit: true });
    dispatchCart({ type: 'addItem', payload: { id, value: cartItem } });
    // route to cart
    if (formType === 'addMore') {
      setTimeout(() => {
        resetForm();
        setState({ submit: false });
        Router.push('/create-order');
      }, 200);
    } else {
      Router.push('/order-summary');
    }
  };

  // form validation setup
  const validationSchema = Yup.object({
    issue: formValdation.issue,
    customerNotes: formValdation.customerNotes,
    declaredValueUsd: formValdation.declaredValueUsd,
    companyNameEnum: formValdation.companyNameEnum,
    purchasedPrice: formValdation.purchasedPrice,
    purchasedDate: formValdation.purchasedDate,
    planNumber: formValdation.planNumber,
    wnmNumber: formValdation.wnmNumber,
    hasWNM: formValdation.hasWNM,
  });

  /**
   * validateWarranty()
   *
   * @param id item index
   * @param cartItem cart item object
   * @param resetForm
   *
   * Calls the warranty api to validate input information the
   * continue button will be disabled during the call
   *
   * If correct the item will move into the cart
   * If incorrect a error message will appear above the
   * warranty section indicating whether the warranty is
   * an invalid input or has expired
   */
  const validateWarranty = async (id, cartItem, resetForm) => {
    // disable button
    setState({ submit: true });

    // call warranty api
    const validateWarrantyApiUrl = `${process.env.NEXT_PUBLIC_PROXY_MJR_API_URL}/api/maintenanceagreement/validate`;
    const data = {
      MaintenanceAgreementNumber: cartItem.hasWNM ? cartItem.wnmNumber : cartItem.planNumber,
      PurchaseDate: FormatDate(cartItem.purchasedDate),
    };

    /**
     * generateWNMErrorMessage()
     *
     * @param message item index
     *
     * Checks if the error message from api has "Care Plan" text on it. If yes, converts it to WorryNoMore Number.
     * By using this function, alot of lines of code can be saved in the API which is the source of the error message.
     */
    const generateWNMErrorMessage = (message) => {
      if (message.toLowerCase().includes('care plan')) {
        const convertedMessage = message.toLowerCase().replaceAll('care plan', 'WorryNoMore Number');
        return convertedMessage;
      }
      return message;
    };

    await axiosWithMjrAccessToken
      .post(validateWarrantyApiUrl, data)
      .then((response) => {
        // handle success
        // check if valid
        if (response.data.isValid) {
          cartItem.maintenanceAgreementId = response.data.id;
          continueForm(id, cartItem, resetForm);
        } else {
          setState({
            hasMessage: true,
            message: cartItem.hasWNM ? generateWNMErrorMessage(response.data.message) : response.data.message,
            submit: false,
          });
        }
      })
      .catch((error) => {
        logError(error, 'validateWarranty - /components/CreateOrder/CreateOrder.js');
        setState({
          hasMessage: true,
          message: 'There was an error with the server. Please try again.',
          submit: false,
        });
      });
  };

  /**
   * submit
   *
   * @param data - form data
   *
   * Handles adding an item to the cart when a user hits continue
   * @param root0
   * @param root0.resetForm
   */
  const submit = (data, { resetForm }) => {
    // set id
    const id = routeParams.id === undefined ? null : routeParams.id;
    // set cartItem to data
    const cartItem = data;
    // check for warranty company
    if (cartItem.companyNameEnum === '4' || (cartItem.companyNameEnum === '1' && cartItem.hasWNM)) {
      validateWarranty(id, cartItem, resetForm);
    } else {
      continueForm(id, cartItem, resetForm);
    }
  };

  const submitHandler = (submitForm, button) => {
    setFormType(button);
    submitForm();
  };

  const { id } = routeParams;
  // default values for form
  const values = {
    statusEnum: 'Open',
    issue: '',
    customerNotes: '',
    declaredValueUsd: '',
    planNumber: '',
    wnmNumber: '',
    companyNameEnum: '',
    purchasedPrice: '',
    purchasedDate: null,
    boxNeeded: 0,
    hasWNM: false,
  };
  if (site.getSite.includes('montblanc')) {
    values.companyNameEnum = '6';
  }
  if (site.getSite.includes('macys')) {
    values.companyNameEnum = '1';
  }
  return (
    <div>
      <Formik
        initialValues={!id || id === '' ? values : cart.items[id]}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={submit}
      >
        {(props) => (
          <CreateOrderForm
            {...props}
            warrantyError={state}
            submitting={state.submit}
            submitHandler={submitHandler}
          />
        )}
      </Formik>
    </div>
  );
};

export default CreateOrder;
