import CreateOrder from 'components/CreateOrder/CreateOrder';
import React from 'react';
import { Typography } from '@material-ui/core';
import { useContent } from 'utils/swrCalls';
import { formatImage } from 'utils/imageService';
import * as site from 'utils/getSite';

const clickHereUrl = `${process.env.NEXT_PUBLIC_MJR_URL}`;

/**
 * Create Order Page
 */
const CreateOrderPage = () => {
  const { data: content } = useContent();
  const bannerImage = `url(${formatImage(content?.content?.pages?.createOrder?.bannerImage)})`;
  return (
    <>
      {content?.content?.pages?.createOrder
        ? (
          <div className={`content-card ${bannerImage !== '' ? 'banner-header' : ''}`} style={{ backgroundImage: bannerImage !== '' ? bannerImage : '' }}>
            <div className='content'>
              <Typography variant='h1'>{content?.content?.pages?.createOrder.title}</Typography>
              {content?.content?.pages?.createOrder?.bullets?.map((value, index) => (
                <div key={`bullet-${index}`}>
                  <Typography variant='h3'>{value?.title}</Typography>
                  <Typography variant='body1' className='bullet'>{value.body}</Typography>
                  <Typography variant='body2' className='bullet2'>
                    {value?.body2 }
                    { value?.body2 && site.getSite.includes('macys') ? <a href={clickHereUrl}>{'here.'}</a> : ''}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )
        : (
          <div className='content-card'>
            <Typography variant='h1'>{'Place Your Order in 3 Steps'}</Typography>
            <Typography variant='h3'>{'1. Tell us about your repair'}</Typography>
            <Typography variant='body1'>{'Describe your jewelry and the repair.'}</Typography>
            <Typography variant='h3'>{'2. Select insurance and shipping'}</Typography>
            <Typography variant='body1'>
              {
                'We provide free ground shipping via USPS ParcelSelect. Insurance and other shipping methods are available for an additional fee.'
              }
            </Typography>
            <Typography variant='h3'>{'3. Ship your items'}</Typography>
            <Typography variant='body1'>
              {
                'Package up your items as instructed. When finished, either schedule a pickup or drop your package off at a carrier location near you.'
              }
            </Typography>
          </div>
        )}
      <div className='content-card'>
        <CreateOrder />
      </div>
    </>
  );
};

export default CreateOrderPage;
