import React from 'react';
import {
  CircularProgress,
  Typography,
} from '@material-ui/core';

const LoadingSpinner = (props) => {
  const { loading, message, children } = props;

  if (loading) {
    return (
      <div className='center'>
        <CircularProgress size={30} />
        <Typography variant='body1'>
          {message}
        </Typography>
      </div>
    );
  }
  return children;
};

export default LoadingSpinner;
