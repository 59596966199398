import NumberFormat from 'react-number-format';

const DollarInput = (props) => {
  const {
    inputRef, onChange, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (props.value !== values.value) {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }
      }}
      thousandSeparator
      prefix='$ '
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

export default DollarInput;
