import 'date-fns';

import {
  FormControl, TextField, Typography, Switch, Grid, withStyles,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';
import DollarInput from 'components/Global/DollarInput';
import { useContent } from 'utils/swrCalls';

const CreateOrderFormWarrantyFields = (props) => {
  const { data: content } = useContent();
  const {
    values: {
      purchasedPrice, purchasedDate, planNumber, companyNameEnum, wnmNumber, boxNeeded, issue, hasWNM,
    },
    handleChange,
    setFieldValue,
    errors,
    warrantyError,
  } = props;
  const setDateField = (date) => {
    setFieldValue('purchasedDate', date, true);
  };

  const setBoxField = () => {
    if (companyNameEnum === '6') {
      setFieldValue('boxNeeded', (boxNeeded === 0 ? 1 : 0), true);
    } else if (companyNameEnum === '1') {
      setFieldValue('hasWNM', !hasWNM, true);
    }
  };

  const setPurchasedAndDeclaredPrice = (price) => {
    setFieldValue('purchasedPrice', price.target.value, true);
    setFieldValue('declaredValueUsd', price.target.value, true);
  };

  const CustomSwitch = withStyles((theme) => ({
    root: {
      width: 36,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(19px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.secondary,
          borderColor: theme.palette.primary.secondary,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${props.disabled ? theme.palette.grey[500] : theme.palette.primary}`,
      borderRadius: 16 / 2,
      opacity: 1,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary,
    },
    checked: {},
  }))(Switch);
  const warrantyType = (companyNameEnum) => {
    switch (parseInt(companyNameEnum)) {
      case 4:
        return (
          <>
            <div className='question-5'>
              <FormControl fullWidth variant='outlined'>
                <TextField
                  id='purchasePrice'
                  name='purchasedPrice'
                  label='Purchase Price'
                  variant='outlined'
                  value={purchasedPrice}
                  error={Boolean(errors.purchasedPrice)}
                  helperText={errors?.purchasedPrice ? errors?.purchasedPrice : ''}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: DollarInput,
                  }}
                />
              </FormControl>
            </div>
            <div className='question-6'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  format='MM/dd/yyyy'
                  placeholder='mm/dd/yyyy'
                  margin='normal'
                  label='Purchase Date'
                  value={purchasedDate}
                  onChange={setDateField}
                  error={Boolean(errors?.purchasedDate)}
                  helperText={errors?.purchasedDate ? errors?.purchasedDate : ''}
                  inputProps={{
                    name: 'purchasedDate',
                    id: 'purchaseDate',
                  }}
                  inputVariant='outlined'
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className='question-7'>
              <TextField
                required
                type='text'
                label='Sears Care Plan Sales Check Number:'
                error={Boolean(errors?.planNumber)}
                name='planNumber'
                value={planNumber}
                onChange={handleChange}
                variant='outlined'
                helperText={errors?.planNumber ? errors?.planNumber : ''}
                inputProps={{
                  id: 'searsCarePlanNumber',
                }}
              />
              {warrantyError.hasMessage && (
                <Typography variant='body1' className='error'>
                  {warrantyError.message}
                </Typography>
              )}
            </div>
          </>
        );
      case 6:
        return (
          <div className='question-8'>
            {(issue === 'Watch' || issue === 'Writing Instrument') && (
              <div>
                <p>
                  {'Would you like to receive a protective shipping box and packing materials along with your insured shipping label?'}
                </p>
                <FormControl variant='outlined'>
                  <Typography component='div'>
                    <Grid component='label' container alignItems='center' spacing={1}>
                      <Grid item>{'No'}</Grid>
                      <Grid item>
                        <CustomSwitch
                          checked={boxNeeded === 1}
                          value={boxNeeded}
                          onChange={setBoxField}
                          inputProps={{
                            'aria-label': 'box needed checkbox',
                            name: 'boxNeeded',
                          }}
                        />
                      </Grid>
                      <Grid item>{'Yes'}</Grid>
                    </Grid>
                  </Typography>
                </FormControl>
              </div>
            )}
          </div>
        );
      case 1:
        return (
          <>
            <div className='question-8'>
              <p>
                {'Does Your Item have a WORRYNOMORE plan?'}
              </p>
              <FormControl variant='outlined'>
                <Typography component='div'>
                  <Grid component='label' container alignItems='center' spacing={1}>
                    <Grid item>{'No'}</Grid>
                    <Grid item>
                      <CustomSwitch
                        checked={hasWNM}
                        value={hasWNM}
                        onChange={setBoxField}
                        inputProps={{
                          'aria-label': 'box needed checkbox',
                          name: 'hasWNM',
                        }}
                      />
                    </Grid>
                    <Grid item>{'Yes'}</Grid>
                  </Grid>
                </Typography>
              </FormControl>
            </div>
            {
              hasWNM && (
                <>
                  <div className='question-5'>
                    <FormControl fullWidth variant='outlined'>
                      <TextField
                        id='purchasePrice'
                        name='purchasedPrice'
                        label='Purchase Price'
                        variant='outlined'
                        value={purchasedPrice}
                        error={Boolean(errors.purchasedPrice)}
                        helperText={errors?.purchasedPrice ? errors?.purchasedPrice : ''}
                        onChange={setPurchasedAndDeclaredPrice}
                        // onChange={handleChange}
                        InputProps={{
                          inputComponent: DollarInput,
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className='question-6'>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        format='MM/dd/yyyy'
                        placeholder='mm/dd/yyyy'
                        margin='normal'
                        label='Purchase Date'
                        value={purchasedDate}
                        onChange={setDateField}
                        error={hasWNM ? Boolean(errors?.purchasedDate) : null}
                        helperText={hasWNM ? errors?.purchasedDate ? errors?.purchasedDate : '' : null}
                        inputProps={{
                          name: 'purchasedDate',
                          id: 'purchaseDate',
                        }}
                        inputVariant='outlined'
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className='question-7'>
                    <TextField
                      required
                      type='text'
                      label='WorryNoMore Number:'
                      name='wnmNumber'
                      value={wnmNumber}
                      onChange={handleChange}
                      variant='outlined'
                      error={hasWNM ? Boolean(errors?.wnmNumber) : null}
                      helperText={hasWNM ? errors?.wnmNumber ? errors?.wnmNumber : '' : null}
                      inputProps={{
                        id: 'wnmPlanNumber',
                      }}
                    />
                    {warrantyError.hasMessage && (
                      <Typography variant='body1' className='error'>
                        {warrantyError.message}
                      </Typography>
                    )}
                  </div>
                </>
              )
            }
          </>
        );
      default:
        return (
          <Typography variant='body1'>
            {content?.content?.form?.warrantyInfo
              ? (
                content?.content?.form?.warrantyInfo
              )
              : (
                'If your item has a manufacturer’s warranty, please enclose a legible original or photocopy of your Proof of Purchase, along with any other pertinent documentation provided by the manufacturer.'
              )}
          </Typography>
        );
    }
  };

  return <div className='warranty-fields'>{warrantyType(companyNameEnum)}</div>;
};

export default CreateOrderFormWarrantyFields;
